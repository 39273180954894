import Adds from "./components/Adds/Adds";
import Article from "./components/Article/Article";
import ArticleSlider from "./components/ArticleSlider/ArticleSlider";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import MoreArticle from "./components/MoreArticle/MoreArticle";
import Slider from "./components/Slider/Slider";
import Sponser from "./components/Sponser/Sponser";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import { Route, Routes } from "react-router-dom";
import StoreDetail from "./components/StoreDetail/StoreDetail";
import Store from "./components/Store/Store";
import Blogs from "./components/Blogs/Blogs";
import BlogsDetail from "./components/BlogsDetail/BlogsDetail";
import Profile from "./components/Profile/Profile";
import Experience from "./components/Experience/Experience";
import ExperienceDetail from "./components/Experience/ExperienceDetail";




// Create a layout for pages with header and footer
function DefaultLayout({ children }) {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Routes>
      {/* Routes with the default layout */}
      <Route
        path="/"
        element={
          <DefaultLayout>
            <Hero />
            <Slider />
            <Article />
            <Adds />
            <MoreArticle />
            <ArticleSlider />
            <Sponser />
          </DefaultLayout>
        }
      />

      <Route
        path="/blogs"
        element={
          <DefaultLayout>
            <Blogs />
          </DefaultLayout>
        }
      />
      <Route
        path="/blogsDetail"
        element={
          <DefaultLayout>
            <BlogsDetail />
          </DefaultLayout>
        }
      />
      <Route
        path="/storeDetail"
        element={
          <DefaultLayout>
            <StoreDetail />
          </DefaultLayout>
        }
      />

      <Route
        path="/store"
        element={
          <DefaultLayout>
            <Store />
          </DefaultLayout>
        }
      />

      <Route
        path="/profile"
        element={
          <DefaultLayout>
            <Profile />
          </DefaultLayout>
        }
      />

      <Route
        path="/experience"
        element={
          <DefaultLayout>
            <Experience />
          </DefaultLayout>
        }
      />

      <Route
        path="/experienceDetail"
        element={
          <DefaultLayout>
            <ExperienceDetail />
          </DefaultLayout>
        }
      />

      {/* Routes without the default layout */}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      {/* Add more standalone routes as needed */}

    </Routes>
  );
}

export default App;
