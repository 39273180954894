import React, { useState } from "react";
import "./BlogsDetail.css";
import {
  ShareOutlined,
  ThumbDownOffAlt,
  ThumbUpAlt,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Accordian from "./Accordian";

const BlogsDetail = () => {
  const [isOpen, setIsOpen] = useState(1);
  const [close, setClose] = useState(true);

  const toggleAccordion = (value) => {
    setIsOpen(value);
  };
  return (
    <div className="blogsdetail">
      <div className="blogsdetailheader">
        <div className="author">
          <img src="/img/blogsdetailauthor.png" alt="bg" />
          <h3>Ishtiaq’s Story</h3>
        </div>
        <p className="title">
          North India | Delhi | Red Fort | Delhi | Red Fort | Delhi | Red Fort
        </p>
      </div>

      <div className="blogsdetailcontent">
        <button>Nature</button>
        <h1>
          Embrace nature's grace, leave a green trace. Preserve nature's allure,
        </h1>
        <div className="reactions">
          <p className="date">August 20, 2022</p>
          <div className="icons">
            {/* <IconButton> */}
            <ThumbUpAlt />
            {/* </IconButton> */}
            {/* <IconButton> */}
            <ThumbDownOffAlt className="down" />
            {/* </IconButton> */}
            {/* <IconButton> */}
            <ShareOutlined className="share" />
            {/* </IconButton> */}
          </div>
        </div>
        <div className="image">
          <img src="/img/blogsdetailbg.png" alt="bg" />
        </div>
        <p className="details">
          Traveling is an enriching experience that opens up new horizons,
          exposes us to different cultures, and creates memories that last a
          lifetime. However, traveling can also be stressful and overwhelming,
          especially if you don't plan and prepare adequately. In this blog
          article, we'll explore tips and tricks for a memorable journey and how
          to make the most of your travels.
        </p>
      </div>

      <div className="adds">
        <img src="/img/addimg.png" alt="adds" />
      </div>

      <div className="blogsdetailaccordian">
        <p className="accordiancontent">
          Before embarking on your journey, take the time to research your
          destination. This includes understanding the local culture, customs,
          and laws, as well as identifying top attractions, restaurants, and
          accommodations. Doing so will help you navigate your destination with
          confidence and avoid any cultural faux pas.
        </p>
        <div className="summary">
          <h4>Summary </h4>:
          <p>
            Before embarking on your journey, take the time to research your
            destination. This includes understanding the local culture, customs,
            and laws, as well as identifying top attractions, restaurants, and
            accommodations.
          </p>
        </div>
        <div className="accordiansection">
          <Accordian
            heading="What services does TanahAir Offer?"
            para="TanahAir offers a service for creating a website design,
            illustration, icon set,"
            number="1234"
            img="/img/blogsdetailaccordian.png"
          />
          <Accordian
            heading="What services does TanahAir Offer?"
            para="TanahAir offers a service for creating a website design,
            illustration, icon set,"
            number="1234"
            img="/img/blogsdetailaccordian.png"
          />
          <Accordian
            heading="What services does TanahAir Offer?"
            para="TanahAir offers a service for creating a website design,
            illustration, icon set,"
            number="1234"
            img="/img/blogsdetailaccordian.png"
          />
          <Accordian
            heading="What services does TanahAir Offer?"
            para="TanahAir offers a service for creating a website design,
            illustration, icon set,"
            number="1234"
            img="/img/blogsdetailaccordian.png"
          />
        </div>
      </div>
    </div>
  );
};

export default BlogsDetail;
