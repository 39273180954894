import React from "react";
import "./Adds.css";

const Adds = () => {
  return (
    <div className="addSection p-5">
      <img src="/img/addimg.png" alt="add" />
    </div>
  );
};

export default Adds;
