import React from "react";
import "./ExperienceDetail.css";
// import { Calendar } from "@/components/ui/calendar";
import { CreateOutlined, FileUploadOutlined } from "@mui/icons-material";
// import { Calender } from "./Calender";

const ExperienceDetail = () => {
  // const [date, setDate] = (React.useState < Date) | (undefined > new Date());
  return (
    <div className="experienceDetail">
      <div className="detail">
        <div className="left">
          <h4>Share Your Experience</h4>
          <form action="">
            <label htmlFor="">Category</label>
            <select name="" id="" placeholder="weekend tour">
              <option value="">Weekend Tour</option>
              <option value="">Monday Tour</option>
              <option value="">Year Tour</option>
            </select>
            <label htmlFor="">Visited Places (Add places using comma)</label>
            <div className="input">
              <input type="text" placeholder="Simla, kulu, manali" />
              <CreateOutlined />
            </div>

            <label htmlFor="">Trip Duration (in days))</label>
            <div className="input">
              <input type="text" placeholder="3" />
              <CreateOutlined />
            </div>

            <label htmlFor="">Headline</label>
            <div className="input">
              <input type="text" placeholder="This is blog headlinev" />
              <CreateOutlined />
            </div>
          </form>
        </div>
        <div className="right">
          <img src="/img/calender.png" alt="cal" />
          {/*  <div className="box">
            <h3>Date Of Journey</h3>
             <Calendar
              mode="single"
              selected={date}
              onSelect={setDate}
              className="rounded-md border"
            />
            <Calender />
          </div> */}
        </div>
      </div>

      <div className="detailbox">
        <label htmlFor="Description">Description</label>
        <div className="input">
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder="Add a Description"
          ></textarea>
          <CreateOutlined />
        </div>
      </div>

      <div className="upload">
        <div className="upload-thumbnail">
          <div className="thumbnail">
            <FileUploadOutlined />
            <h3>Upload Thumbnail </h3>
          </div>
          <div className="thumnailimg">
            <img src="/img/blogimg.png" alt="bg" />
            <p>Image size must not cross 194H x 416W</p>
          </div>
        </div>
        <div className="upload-blog-image">
          <div className="thumbnail">
            <FileUploadOutlined />
            <h3>Upload Blog image </h3>
          </div>
          <div className="thumnailimg">
            <img src="/img/blogsdetailbg.png" alt="bg" />
            <p>Image size must not cross 194H x 416W</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceDetail;
