import {
  AddCircleOutlined,
  CalendarMonth,
  DeleteOutline,
  EditCalendarOutlined,
  EditOutlined,
  RemoveCircle,
  RemoveCircleOutlined,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import "./Profile.css";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";

const Profile = () => {
  const [manageAddress, setManageAddress] = useState(true);

  const [manageBlog, setManageBlog] = useState(true);
  const [manageBlogBtn, setManageBlogBtn] = useState(true);
  const [manageOrder, setManageOrder] = useState(false);
  const [manageOrderBtn, setManageOrderBtn] = useState(false);

  const [isOpen, setIsOpen] = useState("one");

  const [showSecondSection, setShowSecondSection] = useState(true);
  const [showThirdSection, setShowThirdSection] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setShowSecondSection(false);
        setShowThirdSection(false);
      } else {
        setShowSecondSection(true);
        setShowThirdSection(true);
      }
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Initial check for screen width on component mount
    handleResize();

    // // Clean up the event listener on component unmount
    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, []);

  // const toggleSecondSection = () => {
  //   setShowSecondSection(!showSecondSection);
  //   setShowThirdSection(false);
  // };

  // const toggleThirdSection = (e) => {
  //   e.preventDefault(); // Prevent default form submission behavior
  //   setShowThirdSection(!showThirdSection);
  //   // setShowSecondSection(false); // Close the second section
  // };

  const toggleAccordion = (value) => {
    setIsOpen(value);
  };

  return (
    <div className="profile">
      <div className="profileSection">
        <div className="left">
          <div className="profileInfo">
            <div className="header">
              <h5>Profile info</h5>
              <button>Create a blog +</button>
            </div>
            <div className="user">
              <div className="userimg">
                <img src="/img/profileUser.png" alt="bg" />
                <img src="/img/profileCamera.png" alt="bg" />
              </div>
              <h5>Koushik Saha"</h5>
            </div>
            <div className="end">
              <div className="score">
                <p>Total scout coin earned :</p>
                <div className="coin">
                  <img src="/img/blogsdetailaccordian.png" alt="bg" /> 1234
                </div>
              </div>
              <div className="EditForget">
                <p>
                  EditProfile <EditCalendarOutlined />
                </p>
                <p>Forget password?</p>
              </div>
            </div>
          </div>
          <div className="profileForm">
            <input type="email" placeholder="Aliishtiaq@gmail.com" />
            <div className="firstLast">
              <div>
                <label htmlFor="">First Name</label>
                <div className="inputfield">
                  <input type="text" placeholder="Alex" />
                  <EditOutlined />
                </div>
              </div>
              <div>
                <label htmlFor="">Last Name</label>
                <div className="inputfield">
                  <input type="text" placeholder="Hales" />
                  <EditOutlined />
                </div>
              </div>
            </div>
            <label htmlFor="">Contact Number</label>
            <div className="inputfield">
              <input type="text" placeholder="+994****456789" />
              <EditOutlined />
            </div>
            <label htmlFor="">Date of birth</label>
            <div className="inputfield">
              <input type="date" placeholder="12/6/1993" />
              {/* <CalendarMonth /> */}
            </div>
          </div>
          {/* <div className="colHandlerBtn">
            <button onClick={toggleSecondSection}>Show More</button>
          </div> */}
        </div>
        {/* {showSecondSection && ( */}
        <div className="right">
          <div className="manageAddress">
            <h5> Manage Adress</h5>
            {manageAddress ? (
              <RemoveCircle
                className="red"
                onClick={() => setManageAddress(false)}
              />
            ) : (
              <AddCircleOutlined
                className="red"
                onClick={() => setManageAddress(true)}
              />
            )}
          </div>
          {manageAddress && (
            <div className="savedaddresscards">
              <div className={`card ${isOpen === "one" ? "bgActive" : ""}`}>
                <div className="cardheader">
                  <h5 className={`${isOpen !== "one" ? "orange" : ""}`}>
                    Home Adress
                  </h5>
                  <div className="icons">
                    <DeleteOutline />
                    {isOpen === "one" ? (
                      <RemoveCircleOutlined
                        className={`${isOpen !== "one" ? "green" : "red"}`}
                        onClick={() => {
                          toggleAccordion("");
                        }}
                      />
                    ) : (
                      <AddCircleOutlined
                        className={`${isOpen !== "one" ? "green" : ""}`}
                        onClick={() => {
                          toggleAccordion("one");
                        }}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`cardbody ${
                    isOpen === "one" ? "showCardBody" : ""
                  }`}
                >
                  <div className="content">
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>State</h5>
                    <p>Islamabad</p>
                    <h5>Pin Code</h5>
                    <p>12345</p>
                  </div>
                </div>
              </div>
              <div className={`card ${isOpen === "two" ? "bgActive" : ""}`}>
                <div className="cardheader">
                  <h5 className={`${isOpen !== "two" ? "orange" : ""}`}>
                    Home Adress
                  </h5>
                  <div className="icons">
                    <DeleteOutline />
                    {isOpen === "two" ? (
                      <RemoveCircleOutlined
                        className={`${isOpen !== "two" ? "green" : "red"}`}
                        onClick={() => {
                          toggleAccordion("");
                        }}
                      />
                    ) : (
                      <AddCircleOutlined
                        className={`${isOpen !== "two" ? "green" : ""}`}
                        onClick={() => {
                          toggleAccordion("two");
                        }}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`cardbody ${
                    isOpen === "two" ? "showCardBody" : ""
                  }`}
                >
                  <div className="content">
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>State</h5>
                    <p>Islamabad</p>
                    <h5>Pin Code</h5>
                    <p>12345</p>
                  </div>
                </div>
              </div>
              <div className={`card ${isOpen === "three" ? "bgActive" : ""}`}>
                <div className="cardheader">
                  <h5 className={`${isOpen !== "three" ? "orange" : ""}`}>
                    Home Adress
                  </h5>
                  <div className="icons">
                    <DeleteOutline />
                    {isOpen === "three" ? (
                      <RemoveCircleOutlined
                        className={`${isOpen !== "three" ? "green" : "red"}`}
                        onClick={() => {
                          toggleAccordion("");
                        }}
                      />
                    ) : (
                      <AddCircleOutlined
                        className={`${isOpen !== "three" ? "green" : ""}`}
                        onClick={() => {
                          toggleAccordion("three");
                        }}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`cardbody ${
                    isOpen === "three" ? "showCardBody" : ""
                  }`}
                >
                  <div className="content">
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>State</h5>
                    <p>Islamabad</p>
                    <h5>Pin Code</h5>
                    <p>12345</p>
                  </div>
                </div>
              </div>
              <button>+ Add address</button>
            </div>
          )}
          {/* <div className="colHandlerBtn">
              <button onClick={toggleThirdSection}>Show More</button>
            </div> */}
        </div>
        {/* )} */}
      </div>
      {/* {showThirdSection && ( */}
      <div className="manageblogs">
        <div className="btns">
          <button className={`${manageBlogBtn ? "active" : ""}`}>
            <h5 className={`${manageOrderBtn ? "red" : "green"}`}>
              Manage Blogs
            </h5>
            {manageBlogBtn ? (
              <RemoveCircle
                className="red"
                onClick={() => {
                  setManageBlogBtn(false);
                  setManageBlog(false);
                  setManageOrder(true);
                  setManageOrderBtn(true);
                }}
              />
            ) : (
              <AddCircleOutlined
                className="green"
                onClick={() => {
                  setManageBlogBtn(true);
                  setManageBlog(true);
                  setManageOrder(false);
                  setManageOrderBtn(false);
                }}
              />
            )}
          </button>
          <button className={`${manageOrderBtn ? "active" : ""}`}>
            <h5 className={`${manageOrderBtn ? "red" : "green"}`}>
              Manage Order
            </h5>
            {manageOrderBtn ? (
              <RemoveCircle
                className="red"
                onClick={() => {
                  setManageOrderBtn(false);
                  setManageOrder(false);
                  setManageBlog(true);
                  setManageBlogBtn(true);
                }}
              />
            ) : (
              <AddCircleOutlined
                className="green"
                onClick={() => {
                  setManageOrderBtn(true);
                  setManageOrder(true);
                  setManageBlog(false);
                  setManageBlogBtn(false);
                }}
              />
            )}
          </button>
        </div>

        <div className="articleSlider ">
          {manageBlog && (
            <div className="sliderSection articlesliderSection">
              <ScrollingCarousel>
                <div className="sliderCards">
                  <div className="sliderCard">
                    <img src="/img/sliderimg4.png" alt="bg" />
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Nec vestibulum
                      eget duis quam leo nec.
                    </p>
                  </div>
                  <div className="sliderCard">
                    <img src="/img/sliderimg4.png" alt="bg" />
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Nec vestibulum
                      eget duis quam leo nec.
                    </p>
                  </div>
                  <div className="sliderCard">
                    <img src="/img/sliderimg4.png" alt="bg" />
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Nec vestibulum
                      eget duis quam leo nec.
                    </p>
                  </div>
                </div>
              </ScrollingCarousel>
              <div className="sliderBtns">
                <div className="viewmorebtn">View More</div>
              </div>
            </div>
          )}
          {manageOrder && (
            <div className="sliderSection articlesliderSection">
              <ScrollingCarousel>
                <div className="sliderCards">
                  <div className="sliderCard">
                    <img src="/img/sliderimg1.png" alt="bg" />
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Nec vestibulum
                      eget duis quam leo nec.
                    </p>
                  </div>
                  <div className="sliderCard">
                    <img src="/img/sliderimg2.png" alt="bg" />
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Nec vestibulum
                      eget duis quam leo nec.
                    </p>
                  </div>
                  <div className="sliderCard">
                    <img src="/img/sliderimg3.png" alt="bg" />
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Nec vestibulum
                      eget duis quam leo nec.
                    </p>
                  </div>
                </div>
              </ScrollingCarousel>
              <div className="sliderBtns">
                <div className="viewmorebtn">View More</div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* )} */}
      <div className="manageblogs manageblogsMobile">
        <button className={`${manageBlogBtn ? "active" : ""}`}>
          <h5 className={`${manageOrderBtn ? "red" : "green"}`}>
            Manage Blogs
          </h5>
          {manageBlogBtn ? (
            <RemoveCircle
              className="red"
              onClick={() => {
                setManageBlogBtn(false);
                setManageBlog(false);
              }}
            />
          ) : (
            <AddCircleOutlined
              className="green"
              onClick={() => {
                setManageBlogBtn(true);
                setManageBlog(true);
              }}
            />
          )}
        </button>

        <div className="articleSlider ">
          {manageBlog && (
            <div className="sliderSection articlesliderSection">
              <ScrollingCarousel>
                <div className="sliderCards">
                  <div className="sliderCard">
                    <img src="/img/sliderimg4.png" alt="bg" />
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Nec vestibulum
                      eget duis quam leo nec.
                    </p>
                  </div>
                  <div className="sliderCard">
                    <img src="/img/sliderimg4.png" alt="bg" />
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Nec vestibulum
                      eget duis quam leo nec.
                    </p>
                  </div>
                  <div className="sliderCard">
                    <img src="/img/sliderimg4.png" alt="bg" />
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Nec vestibulum
                      eget duis quam leo nec.
                    </p>
                  </div>
                </div>
              </ScrollingCarousel>
              <div className="sliderBtns">
                <div className="viewmorebtn">View More</div>
              </div>
            </div>
          )}
          <button className={`${manageOrderBtn ? "active" : ""}`}>
            <h5 className={`${manageOrderBtn ? "red" : "green"}`}>
              Manage Order
            </h5>
            {manageOrderBtn ? (
              <RemoveCircle
                className="red"
                onClick={() => {
                  setManageOrderBtn(false);
                  setManageOrder(false);
                }}
              />
            ) : (
              <AddCircleOutlined
                className="green"
                onClick={() => {
                  setManageOrderBtn(true);
                  setManageOrder(true);
                }}
              />
            )}
          </button>
          {manageOrder && (
            <div className="sliderSection articlesliderSection">
              <ScrollingCarousel>
                <div className="sliderCards">
                  <div className="sliderCard">
                    <img src="/img/sliderimg1.png" alt="bg" />
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Nec vestibulum
                      eget duis quam leo nec.
                    </p>
                  </div>
                  <div className="sliderCard">
                    <img src="/img/sliderimg2.png" alt="bg" />
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Nec vestibulum
                      eget duis quam leo nec.
                    </p>
                  </div>
                  <div className="sliderCard">
                    <img src="/img/sliderimg3.png" alt="bg" />
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Nec vestibulum
                      eget duis quam leo nec.
                    </p>
                  </div>
                </div>
              </ScrollingCarousel>
              <div className="sliderBtns">
                <div className="viewmorebtn">View More</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
