import React, { useEffect, useState } from "react";
import "./StoreDetail.css";
import {
  AddCircleOutlined,
  Bookmark,
  RemoveCircleOutlined,
  TurnedInNotOutlined,
} from "@mui/icons-material";
// import Card from "./Card";

const StoreDetail = () => {
  const [isOpen, setIsOpen] = useState("");
  const [sizeActive, setSizeActive] = useState("s");

  const [showSecondSection, setShowSecondSection] = useState(true);
  const [showThirdSection, setShowThirdSection] = useState(true);

  const handleSizeActive = (value) => {
    setSizeActive(value);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setShowSecondSection(false);
        setShowThirdSection(false);
      } else {
        setShowSecondSection(true);
        setShowThirdSection(true);
      }
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Initial check for screen width on component mount
    handleResize();

    // // Clean up the event listener on component unmount
    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, []);

  const toggleSecondSection = () => {
    setShowSecondSection(!showSecondSection);
  };

  const toggleThirdSection = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setShowThirdSection(!showThirdSection);
    setShowSecondSection(false); // Close the second section
  };

  const toggleAccordion = (value) => {
    setIsOpen(value);
  };
  return (
    <div className="storedetail">
      <div className="col1">
        <div className="detailimg">
          <img src="/img/storedetailbg.png" alt="bg" />
        </div>
        <div className="productname">
          <h1>Product Name</h1>
          <div className="smimg">
            <img src="/img/storedetailsm1.png" alt="bg" />
            <img src="/img/storedetailsm2.png" alt="bg" />
          </div>
        </div>
        <div className="productdetail">
          <div className="sizeprice">
            <div className="size">
              <h5>
                Size <a href="">Size chart</a>
              </h5>
              <div className="sizes">
                <span
                  className={`${sizeActive === "s" ? "sizeActive" : ""}`}
                  onClick={() => {
                    handleSizeActive("s");
                  }}
                >
                  S
                </span>
                <span
                  className={`${sizeActive === "m" ? "sizeActive" : ""}`}
                  onClick={() => {
                    handleSizeActive("m");
                  }}
                >
                  M
                </span>
                <span
                  className={`${sizeActive === "l" ? "sizeActive" : ""}`}
                  onClick={() => {
                    handleSizeActive("l");
                  }}
                >
                  L
                </span>
                <span
                  className={`${sizeActive === "xl" ? "sizeActive" : ""}`}
                  onClick={() => {
                    handleSizeActive("xl");
                  }}
                >
                  XL
                </span>
              </div>
            </div>
            <div className="price">
              <h5>Price :</h5>
              <p className="priceimg">
                <img src="/img/blogsdetailaccordian.png" alt="bg" /> 1234
              </p>
            </div>
          </div>
          <ul>
            <li>
              3-in-1 design with onboard crevice tool allows you to customize
              your vacuum for your needs. At only 4 pounds, you can take the
              Eureka blaze anywhere
            </li>
            <li>
              Eureka's signature swivels steering improves maneuverability and
              cleaning efficiency when compared to standard stick vacuums
            </li>
          </ul>
        </div>
        <div className="colHandlerBtn">
          <button onClick={toggleThirdSection}>Continue to checkout</button>
        </div>
      </div>

      {/* Second Section */}
      {showSecondSection && (
        <div className="col2">
          <form>
            <h3>Address Details</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse
              eius rem vero autem numquam laborum.
            </p>

            <label htmlFor="">Address Line 1</label>
            <input type="text" />
            <label htmlFor="">Address Line 2</label>
            <input type="text" />
            <label htmlFor="">Landmark</label>
            <input type="text" />

            <label htmlFor="">Country</label>
            <select>
              <option value=""></option>
              <option value="">one</option>
              <option value="">two</option>
              <option value="">three</option>
            </select>
            <label htmlFor="">State</label>
            <select>
              <option value=""></option>
              <option value="">one</option>
              <option value="">two</option>
              <option value="">three</option>
            </select>
            <label htmlFor="">District</label>
            <select>
              <option value=""></option>
              <option value="">one</option>
              <option value="">two</option>
              <option value="">three</option>
            </select>
            <label htmlFor="">Pin Code</label>
            <input type="text" />
            <label htmlFor="">Post Office</label>
            <select>
              <option value=""></option>
              <option value="">one</option>
              <option value="">two</option>
              <option value="">three</option>
            </select>
          </form>
          <div className="formbtn">
            <button>Save & continue</button>
          </div>
        </div>
      )}
      {/* Button to toggle the Third Section */}
      {/* {showSecondSection && showThirdSection === false && ( */}
      {/* <div className="colHandlerBtn">
        <button onClick={toggleThirdSection}>Continue to checkout</button>
      </div> */}
      {/* )} */}

      {/* Third Section */}
      {showThirdSection && (
        <>
          <div className="col3">
            <div className="saveaddress">
              <h5>Saved Address</h5>
              <Bookmark className="redsvg" />
            </div>
            <div className="savedaddresscards">
              <div className={`card ${isOpen === "one" ? "bgActive" : ""}`}>
                <div className="cardheader">
                  <h5 className={`${isOpen !== "one" ? "orange" : ""}`}>
                    Floyd miles
                  </h5>
                  {isOpen === "one" ? (
                    <RemoveCircleOutlined
                      className={`${isOpen !== "one" ? "orange" : "redsvg"}`}
                      onClick={() => {
                        toggleAccordion("");
                      }}
                    />
                  ) : (
                    <AddCircleOutlined
                      className={`${isOpen !== "one" ? "orange" : ""}`}
                      onClick={() => {
                        toggleAccordion("one");
                      }}
                    />
                  )}
                </div>
                <div
                  className={`cardbody ${
                    isOpen === "one" ? "showCardBody" : ""
                  }`}
                >
                  <div className="content">
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>State</h5>
                    <p>Islamabad</p>
                    <h5>Pin Code</h5>
                    <p>12345</p>
                  </div>
                  <button>
                    Buy at :{" "}
                    <img src="/img/blogsdetailaccordian.png" alt="bg" />
                    1234
                  </button>
                </div>
              </div>

              <div className={`card ${isOpen === "two" ? "bgActive" : ""}`}>
                <div className="cardheader">
                  <h5 className={`${isOpen !== "two" ? "orange" : ""}`}>
                    Floyd miles
                  </h5>
                  {isOpen === "two" ? (
                    <RemoveCircleOutlined
                      className={`${isOpen !== "two" ? "orange" : "redsvg"}`}
                      onClick={() => {
                        toggleAccordion("");
                      }}
                    />
                  ) : (
                    <AddCircleOutlined
                      className={`${isOpen !== "two" ? "orange" : ""}`}
                      onClick={() => {
                        toggleAccordion("two");
                      }}
                    />
                  )}
                </div>
                <div
                  className={`cardbody ${
                    isOpen === "two" ? "showCardBody" : ""
                  }`}
                >
                  <div className="content">
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>State</h5>
                    <p>Islamabad</p>
                    <h5>Pin Code</h5>
                    <p>12345</p>
                  </div>
                  <button>
                    Buy at :{" "}
                    <img src="/img/blogsdetailaccordian.png" alt="bg" />
                    1234
                  </button>
                </div>
              </div>

              <div className={`card ${isOpen === "three" ? "bgActive" : ""}`}>
                <div className="cardheader">
                  <h5 className={`${isOpen !== "three" ? "orange" : ""}`}>
                    Floyd miles
                  </h5>
                  {isOpen === "three" ? (
                    <RemoveCircleOutlined
                      className={`${isOpen !== "three" ? "orange" : "redsvg"}`}
                      onClick={() => {
                        toggleAccordion("");
                      }}
                    />
                  ) : (
                    <AddCircleOutlined
                      className={`${isOpen !== "three" ? "orange" : ""}`}
                      onClick={() => {
                        toggleAccordion("three");
                      }}
                    />
                  )}
                </div>
                <div
                  className={`cardbody ${
                    isOpen === "three" ? "showCardBody" : ""
                  }`}
                >
                  <div className="content">
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>State</h5>
                    <p>Islamabad</p>
                    <h5>Pin Code</h5>
                    <p>12345</p>
                  </div>
                  <button>
                    Buy at :{" "}
                    <img src="/img/blogsdetailaccordian.png" alt="bg" />
                    1234
                  </button>
                </div>
              </div>

              <div className={`card ${isOpen === "four" ? "bgActive" : ""}`}>
                <div className="cardheader">
                  <h5 className={`${isOpen !== "four" ? "orange" : ""}`}>
                    Floyd miles
                  </h5>
                  {isOpen === "four" ? (
                    <RemoveCircleOutlined
                      className={`${isOpen !== "four" ? "orange" : "redsvg"}`}
                      onClick={() => {
                        toggleAccordion("");
                      }}
                    />
                  ) : (
                    <AddCircleOutlined
                      className={`${isOpen !== "four" ? "orange" : ""}`}
                      onClick={() => {
                        toggleAccordion("four");
                      }}
                    />
                  )}
                </div>
                <div
                  className={`cardbody ${
                    isOpen === "four" ? "showCardBody" : ""
                  }`}
                >
                  <div className="content">
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>Address line 1</h5>
                    <p>Rawalpindi,Islamabad,Pakistan</p>
                    <h5>State</h5>
                    <p>Islamabad</p>
                    <h5>Pin Code</h5>
                    <p>12345</p>
                  </div>
                  <button>
                    Buy at :{" "}
                    <img src="/img/blogsdetailaccordian.png" alt="bg" />
                    1234
                  </button>
                </div>
              </div>
            </div>
            <div className="colHandlerBtn">
              <button onClick={toggleSecondSection}>Add a new address</button>
            </div>
          </div>
          {/* Button to toggle the Second Section */}
          {/* {showSecondSection === false && ( */}
          {/* <div className="colHandlerBtn">
            <button onClick={toggleSecondSection}>Add a new address</button>
          </div> */}
          {/* )} */}
        </>
      )}
    </div>
  );
};

export default StoreDetail;
